const suggestions = [
  "No answer",
  "Not reachable",
  "Hung up",
  "Mute",
  "Third party",
  "No commitment",
  "No precise amount",
  "No precise date",
  "Wrong number",
  "Payment not updated",
  "No knowledge of loan",
  "Deceased",
  "Paid",
  "Language barrier",
  "Switched off", 
];
export default suggestions;
