export const user = [
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "62a322434098040e4053fdc3",
		"firstname": "Ifeoma",
		"middlename": "",
		"lastname": " Akpa",
		"email": "akpa@yahoo.com",
		"userId": "agent1109",
		"contactNo": "07030634",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "62a31dc74098040e4053f79d",
			"firstname": "Michael",
			"lastname": " Osopale",
			"id": "62a31dc74098040e4053f79d"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2022-06-10T10:51:47.130Z",
		"__v": 0,
		"lastLoginDate": "2023-11-15T06:23:20.441Z",
		"id": "62a322434098040e4053fdc3"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "62a321e44098040e4053fd48",
		"firstname": "Sarah ",
		"middlename": "Godwin",
		"lastname": "Izang",
		"email": "sarah@gmail.com",
		"userId": "agent1104",
		"contactNo": "07030634",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "62a31dc74098040e4053f79d",
			"firstname": "Michael",
			"lastname": " Osopale",
			"id": "62a31dc74098040e4053f79d"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2022-06-10T10:50:12.524Z",
		"__v": 0,
		"lastLoginDate": "2022-06-10T14:39:46.746Z",
		"id": "62a321e44098040e4053fd48"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61a0d7c6561ac91f8ce28913",
		"firstname": "Temitope ",
		"middlename": "",
		"lastname": "Adebayo",
		"email": "agent.8217@outcess.com",
		"userId": "agent.8217",
		"contactNo": "09077445533",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-11-26T12:49:10.343Z",
		"__v": 0,
		"lastLoginDate": "2022-06-10T14:23:12.887Z",
		"id": "61a0d7c6561ac91f8ce28913"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "618e2d5b1ea68f06282a5f46",
		"firstname": "Nkechi  Rachael",
		"middlename": "",
		"lastname": "Duruji ",
		"email": "agent.5526@outcess.com",
		"userId": "agent.5526",
		"contactNo": "08055886699",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-11-12T09:01:15.368Z",
		"__v": 0,
		"lastLoginDate": "2023-03-30T08:21:55.315Z",
		"id": "618e2d5b1ea68f06282a5f46"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "618e2cfb1ea68f06282a5b09",
		"firstname": "Vivian ",
		"middlename": "",
		"lastname": "Ogbeide",
		"email": "agent.5270@outcess.com",
		"userId": "agent.5270",
		"contactNo": "08077668844",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-11-12T08:59:39.076Z",
		"__v": 0,
		"lastLoginDate": "2021-11-19T09:16:10.415Z",
		"id": "618e2cfb1ea68f06282a5b09"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61794195cb368b14e02577b6",
		"firstname": "Michael",
		"middlename": "",
		"lastname": "Oni",
		"email": "michael@outcess.com",
		"userId": "agent.8216",
		"contactNo": "1234567890",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-27T12:09:57.850Z",
		"__v": 0,
		"lastLoginDate": "2023-04-25T09:19:13.381Z",
		"id": "61794195cb368b14e02577b6"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61794106cb368b14e0257383",
		"firstname": "Banke",
		"middlename": "",
		"lastname": " Ilawole",
		"email": "banke@outcess.com",
		"userId": "agent.8215",
		"contactNo": "1234567890",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-27T12:07:34.943Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T06:33:19.194Z",
		"id": "61794106cb368b14e0257383"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "616fcb85bba06407e0be4b36",
		"firstname": "Veronica ",
		"middlename": "",
		"lastname": "Agbo",
		"email": "agent.8214@outcess.com",
		"userId": "agent.8214",
		"contactNo": "09099887766",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-20T07:55:49.813Z",
		"__v": 0,
		"lastLoginDate": "2022-07-05T06:48:13.568Z",
		"id": "616fcb85bba06407e0be4b36"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "616fcb58bba06407e0be4992",
		"firstname": "Oladimeji ",
		"middlename": "",
		"lastname": "Adetola",
		"email": "agent.8213@outcess.com",
		"userId": "agent.8213",
		"contactNo": "09090999999",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-20T07:55:04.998Z",
		"__v": 0,
		"lastLoginDate": "2021-11-29T15:31:12.822Z",
		"id": "616fcb58bba06407e0be4992"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "616fcb10bba06407e0be477b",
		"firstname": "Obianuju ",
		"middlename": "",
		"lastname": "Orji",
		"email": "agent.8212@outcess.com",
		"userId": "agent.8212",
		"contactNo": "909777777777",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-20T07:53:52.147Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T06:47:25.154Z",
		"id": "616fcb10bba06407e0be477b"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "616555e3bba06407e0ace02d",
		"firstname": "Akeremale",
		"middlename": "",
		"lastname": "Oluwaseun ",
		"email": "agent.8211@outcess.com",
		"userId": "agent.8211",
		"contactNo": "08099665577",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-12T09:31:15.833Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T07:27:29.161Z",
		"id": "616555e3bba06407e0ace02d"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "616555a1bba06407e0acddc4",
		"firstname": "Adejumoke ",
		"middlename": "",
		"lastname": "Shobowale",
		"email": "agent.8210@outcess.com",
		"userId": "agent.8210",
		"contactNo": "08077886655",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-10-12T09:30:09.883Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T06:48:26.022Z",
		"id": "616555a1bba06407e0acddc4"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61532eaac87c3a1e1c6aee26",
		"firstname": "Habeeb ",
		"middlename": "",
		"lastname": "Dahunsi",
		"email": "agent.8209@outcess.com",
		"userId": "agent.8209",
		"contactNo": "09088557766",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-28T15:03:06.549Z",
		"__v": 0,
		"id": "61532eaac87c3a1e1c6aee26"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61532e6ac87c3a1e1c6aeb8c",
		"firstname": "Kehinde",
		"middlename": "",
		"lastname": "Afuye",
		"email": "agent.8208@outcess.com",
		"userId": "agent.8208",
		"contactNo": "09077556633",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-28T15:02:02.538Z",
		"__v": 0,
		"lastLoginDate": "2021-10-07T14:20:28.706Z",
		"id": "61532e6ac87c3a1e1c6aeb8c"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61532e21c87c3a1e1c6ae853",
		"firstname": "Joyce",
		"middlename": "",
		"lastname": "Samuel",
		"email": "agent.8207@outcess.com",
		"userId": "agent.8207",
		"contactNo": "090922334455",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-28T15:00:49.320Z",
		"__v": 0,
		"lastLoginDate": "2021-10-07T14:20:53.561Z",
		"id": "61532e21c87c3a1e1c6ae853"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "614b071dc87c3a1e1c57db1a",
		"firstname": "Nancy",
		"middlename": "",
		"lastname": "Ogochukwu",
		"email": "agent.8203@outcess.com",
		"userId": "agent.8203",
		"contactNo": "080800000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-22T10:36:13.055Z",
		"__v": 0,
		"lastLoginDate": "2021-11-22T07:10:56.733Z",
		"id": "614b071dc87c3a1e1c57db1a"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "614b06e3c87c3a1e1c57d83b",
		"firstname": "Damilola",
		"middlename": "",
		"lastname": "Adekoya",
		"email": "agent.8206@outcess.com",
		"userId": "agent.8206",
		"contactNo": "080800000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-22T10:35:15.291Z",
		"__v": 0,
		"lastLoginDate": "2021-09-22T10:41:54.036Z",
		"id": "614b06e3c87c3a1e1c57d83b"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "614b068cc87c3a1e1c57d4b5",
		"firstname": "Ajayi",
		"middlename": "",
		"lastname": "Hezekiah",
		"email": "agent.8205@outcess.com",
		"userId": "agent.8205",
		"contactNo": "080800000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-22T10:33:48.713Z",
		"__v": 0,
		"lastLoginDate": "2021-09-24T07:08:21.719Z",
		"id": "614b068cc87c3a1e1c57d4b5"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "614b058dc87c3a1e1c57ca15",
		"firstname": "Esther",
		"middlename": "",
		"lastname": "olise",
		"email": "agent.8204@outcess.com",
		"userId": "agent.8204",
		"contactNo": "080800000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-22T10:29:33.995Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T06:50:45.245Z",
		"id": "614b058dc87c3a1e1c57ca15"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "614b0417c87c3a1e1c57bf8d",
		"firstname": "Nancy",
		"middlename": "",
		"lastname": "Nancy",
		"email": "agent.8023@outcess.com",
		"userId": "agent.8023",
		"contactNo": "080800000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-09-22T10:23:19.688Z",
		"__v": 0,
		"id": "614b0417c87c3a1e1c57bf8d"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "612e5f8a6ad4050a44771c22",
		"firstname": "Ayodele ",
		"middlename": "",
		"lastname": "Akinniyi",
		"email": "agent.8200@outcess.com",
		"userId": "agent.8200",
		"contactNo": "82000000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-31T16:57:46.209Z",
		"__v": 0,
		"lastLoginDate": "2021-10-28T13:03:10.736Z",
		"id": "612e5f8a6ad4050a44771c22"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "612e5ecc6ad4050a44771bfe",
		"firstname": "Oluwatosin ",
		"middlename": "",
		"lastname": "Akangbe",
		"email": "agent.8199@outcess.com",
		"userId": "agent.8199",
		"contactNo": "81990000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-31T16:54:36.189Z",
		"__v": 0,
		"lastLoginDate": "2021-11-18T06:47:34.541Z",
		"id": "612e5ecc6ad4050a44771bfe"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "612e5e4c6ad4050a44771bdf",
		"firstname": "Joel ",
		"middlename": "",
		"lastname": "Joshua",
		"email": "agent.8198@outcess.com",
		"userId": "agent.8198",
		"contactNo": "81980000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-31T16:52:28.321Z",
		"__v": 0,
		"lastLoginDate": "2021-11-23T07:32:08.274Z",
		"id": "612e5e4c6ad4050a44771bdf"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "612e5d9c6ad4050a44771bc0",
		"firstname": "Adetutu",
		"middlename": "",
		"lastname": "Adelugba",
		"email": "agent.8197@outcess.com",
		"userId": "agent.8197",
		"contactNo": "81970000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-31T16:49:32.206Z",
		"__v": 0,
		"lastLoginDate": "2021-11-26T15:33:07.814Z",
		"id": "612e5d9c6ad4050a44771bc0"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "612e5c096ad4050a44771b25",
		"firstname": "Oluwatosin ",
		"middlename": "",
		"lastname": "Adetunji",
		"email": "agent.8196@outcess.com",
		"userId": "agent.8196",
		"contactNo": "81960000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-31T16:42:49.486Z",
		"__v": 0,
		"id": "612e5c096ad4050a44771b25"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "612e56f06ad4050a4477198c",
		"firstname": "Ajoke ",
		"middlename": "",
		"lastname": "Shelle",
		"email": "agent.8195@outcess.com",
		"userId": "agent.8195",
		"contactNo": "81950000000",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-31T16:21:04.580Z",
		"__v": 0,
		"lastLoginDate": "2021-09-02T08:40:37.244Z",
		"id": "612e56f06ad4050a4477198c"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251da850c0a922a0f6b163",
		"firstname": "Makinde",
		"middlename": "",
		"lastname": "Olugbenga",
		"email": "agent.8194@outcess.com",
		"userId": "agent.8194",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:26:16.390Z",
		"__v": 0,
		"lastLoginDate": "2021-09-08T07:42:39.248Z",
		"id": "61251da850c0a922a0f6b163"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "61251d7250c0a922a0f6b144",
		"firstname": "Rukayat ",
		"middlename": "",
		"lastname": "Raji",
		"email": "agent.8193@outcess.com",
		"userId": "agent.8193",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:25:22.640Z",
		"__v": 0,
		"lastLoginDate": "2021-10-07T07:48:36.308Z",
		"id": "61251d7250c0a922a0f6b144"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251d3950c0a922a0f6b125",
		"firstname": "Odunayo ",
		"middlename": "",
		"lastname": "Aremu",
		"email": "agent.8192@outcess.com",
		"userId": "agent.8192",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:24:25.155Z",
		"__v": 0,
		"lastLoginDate": "2021-11-08T11:34:21.712Z",
		"id": "61251d3950c0a922a0f6b125"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251cfd50c0a922a0f6b106",
		"firstname": "Lydia",
		"middlename": "",
		"lastname": "Eluro",
		"email": "agent.8191@outcess.com",
		"userId": "agent.8191",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:23:25.981Z",
		"__v": 0,
		"lastLoginDate": "2021-09-01T07:26:10.256Z",
		"id": "61251cfd50c0a922a0f6b106"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251cc750c0a922a0f6b0e7",
		"firstname": "Lawal",
		"middlename": "",
		"lastname": "Adewale ",
		"email": "agent.8190@outcess.com",
		"userId": "agent.8190",
		"contactNo": "09033442277",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:22:31.968Z",
		"__v": 0,
		"lastLoginDate": "2021-09-01T13:17:34.980Z",
		"id": "61251cc750c0a922a0f6b0e7"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251c8b50c0a922a0f6b0c8",
		"firstname": "Oluwaseye ",
		"middlename": "",
		"lastname": "Sangorinde",
		"email": "agent.8189@outcess.com",
		"userId": "agent.8189",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:21:31.583Z",
		"__v": 0,
		"lastLoginDate": "2021-11-26T13:04:11.214Z",
		"id": "61251c8b50c0a922a0f6b0c8"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251c5350c0a922a0f6b0a9",
		"firstname": "Abdullah ",
		"middlename": "",
		"lastname": "Aladejo",
		"email": "agent.8188@outcess.com",
		"userId": "agent.8188",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:20:35.101Z",
		"__v": 0,
		"lastLoginDate": "2021-11-12T07:45:30.292Z",
		"id": "61251c5350c0a922a0f6b0a9"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251c1f50c0a922a0f6b08a",
		"firstname": "Olayinka ",
		"middlename": "",
		"lastname": "Akiode",
		"email": "agent.8187@outcess.com",
		"userId": "agent.8187",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:19:43.536Z",
		"__v": 0,
		"lastLoginDate": "2021-11-26T07:23:06.495Z",
		"id": "61251c1f50c0a922a0f6b08a"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "61251be450c0a922a0f6b06b",
		"firstname": "Christopher ",
		"middlename": "",
		"lastname": "Edward",
		"email": "agent.8186@outcess.com",
		"userId": "agent.8186",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:18:44.658Z",
		"__v": 0,
		"lastLoginDate": "2021-11-05T06:53:17.556Z",
		"id": "61251be450c0a922a0f6b06b"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251baa50c0a922a0f6b04c",
		"firstname": "Esther ",
		"middlename": "",
		"lastname": "Ugochukwu",
		"email": "agent.8185@outcess.com",
		"userId": "agent.8185",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:17:46.009Z",
		"__v": 0,
		"lastLoginDate": "2021-11-23T06:41:48.922Z",
		"id": "61251baa50c0a922a0f6b04c"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251ae950c0a922a0f6b02d",
		"firstname": "Olalekan",
		"middlename": "",
		"lastname": "Soliu ",
		"email": "agent.8184@outcess.com",
		"userId": "agent.8184",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:14:33.319Z",
		"__v": 0,
		"lastLoginDate": "2021-09-15T15:26:53.727Z",
		"id": "61251ae950c0a922a0f6b02d"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "61251a4250c0a922a0f6aff1",
		"firstname": "Taiwo",
		"middlename": "",
		"lastname": "Dada-Lawrence",
		"email": "agent.8182@outcess.com",
		"userId": "agent.8182",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:11:46.380Z",
		"__v": 0,
		"lastLoginDate": "2021-11-29T15:30:43.443Z",
		"id": "61251a4250c0a922a0f6aff1"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "612519ca50c0a922a0f6afbe",
		"firstname": "Oluwaseun",
		"middlename": "Adegoke",
		"lastname": "Adesoji ",
		"email": "agent.8179@outcess.com",
		"userId": "agent.8179",
		"contactNo": "09022334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:09:46.708Z",
		"__v": 0,
		"lastLoginDate": "2021-09-07T06:37:34.739Z",
		"id": "612519ca50c0a922a0f6afbe"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6125198d50c0a922a0f6af9f",
		"firstname": "Aminat ",
		"middlename": "Oluwatoyin",
		"lastname": "Gbagba ",
		"email": "agent.8178@outcess.com",
		"userId": "agent.8178",
		"contactNo": "09055224477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:08:45.719Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T07:13:14.657Z",
		"id": "6125198d50c0a922a0f6af9f"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6125191a50c0a922a0f6af80",
		"firstname": "Blessing",
		"middlename": "Onyinyechi ",
		"lastname": "Onwuchekwa ",
		"email": "agent.8177@outcess.com",
		"userId": "agent.8177",
		"contactNo": "09066224477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:06:50.978Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T13:21:06.021Z",
		"id": "6125191a50c0a922a0f6af80"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "612518d450c0a922a0f6af61",
		"firstname": "Falowo ",
		"middlename": "Jumoke",
		"lastname": "Oluwabukola ",
		"email": "agent.8176@outcess.com",
		"userId": "agent.8176",
		"contactNo": "09055334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:05:40.386Z",
		"__v": 0,
		"lastLoginDate": "2021-11-29T15:30:32.093Z",
		"id": "612518d450c0a922a0f6af61"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "6125189350c0a922a0f6af42",
		"firstname": "Gabriel ",
		"middlename": "Ademola",
		"lastname": "Mustapha",
		"email": "agent.8175@outcess.com",
		"userId": "agent.8175",
		"contactNo": "09055334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:04:35.397Z",
		"__v": 0,
		"lastLoginDate": "2021-09-06T07:40:49.400Z",
		"id": "6125189350c0a922a0f6af42"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6125183650c0a922a0f6af23",
		"firstname": "Napoleon",
		"middlename": "",
		"lastname": "Ebhaleme",
		"email": "agent.8174@outcess.com",
		"userId": "agent.8174",
		"contactNo": "09055334477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:03:02.107Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T07:13:19.433Z",
		"id": "6125183650c0a922a0f6af23"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "612517ea50c0a922a0f6af04",
		"firstname": "Benjamin",
		"middlename": "",
		"lastname": "Oraih",
		"email": "agent.8173@outcess.com",
		"userId": "agent.8173",
		"contactNo": "09066554477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:01:46.139Z",
		"__v": 0,
		"lastLoginDate": "2021-11-24T06:43:03.159Z",
		"id": "612517ea50c0a922a0f6af04"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "612517ae50c0a922a0f6aee5",
		"firstname": "Ezinne",
		"middlename": "",
		"lastname": "Chukwu",
		"email": "agent.8166@outcess.com",
		"userId": "agent.8166",
		"contactNo": "09055664477",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T16:00:46.922Z",
		"__v": 0,
		"lastLoginDate": "2021-11-29T07:07:44.986Z",
		"id": "612517ae50c0a922a0f6aee5"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6125177650c0a922a0f6aec6",
		"firstname": "Peter",
		"middlename": "",
		"lastname": "Olatunde",
		"email": "agent.8165@outcess.com",
		"userId": "agent.8165",
		"contactNo": "09066773366",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T15:59:50.445Z",
		"__v": 0,
		"lastLoginDate": "2021-08-30T07:20:04.026Z",
		"id": "6125177650c0a922a0f6aec6"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "6125173450c0a922a0f6aea7",
		"firstname": "Princess",
		"middlename": "Onyinye",
		"lastname": "Ezomo",
		"email": "agent.8164@outcess.com",
		"userId": "agent.8164",
		"contactNo": "09066773399",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T15:58:44.473Z",
		"__v": 0,
		"lastLoginDate": "2021-09-30T07:16:02.888Z",
		"id": "6125173450c0a922a0f6aea7"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "612516e150c0a922a0f6ae88",
		"firstname": "Cynthia",
		"middlename": "Sira",
		"lastname": "Eghosa",
		"email": "agent.8157@outcess.com",
		"userId": "agent.8157",
		"contactNo": "09066447788",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T15:57:21.468Z",
		"__v": 0,
		"id": "612516e150c0a922a0f6ae88"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "6125168c50c0a922a0f6ae5a",
		"firstname": "Monday",
		"middlename": "",
		"lastname": "Onakufe",
		"email": "agent.8135@outcess.com",
		"userId": "agent.8135",
		"contactNo": "08066447733",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T15:55:56.760Z",
		"__v": 0,
		"lastLoginDate": "2021-09-16T11:12:29.180Z",
		"id": "6125168c50c0a922a0f6ae5a"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6125165350c0a922a0f6ae39",
		"firstname": "Grace",
		"middlename": "Adejoke",
		"lastname": "Olusoji",
		"email": "agent.8132@outcess.com",
		"userId": "agent.8132",
		"contactNo": "08066557733",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T15:54:59.530Z",
		"__v": 0,
		"lastLoginDate": "2021-11-30T06:31:50.706Z",
		"id": "6125165350c0a922a0f6ae39"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6125160150c0a922a0f6ae1a",
		"firstname": "Omonike",
		"middlename": "",
		"lastname": "Amos",
		"email": "agent.8068@outcess.com",
		"userId": "agent.8068",
		"contactNo": "08066557744",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c2",
			"roleName": "agent"
		},
		"reportsTo": {
			"_id": "6125127250c0a922a0f6ad8f",
			"firstname": "Clement",
			"lastname": "Odere",
			"id": "6125127250c0a922a0f6ad8f"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T15:53:37.694Z",
		"__v": 0,
		"lastLoginDate": "2021-09-17T08:03:23.139Z",
		"id": "6125160150c0a922a0f6ae1a"
	},
	{
		"isActive": false,
		"lob": "Branch",
		"loginFlag": false,
		"_id": "6124ff8c0745811f48fba924",
		"firstname": "Babatunde",
		"middlename": "",
		"lastname": "Bamidele",
		"email": "babatunde.bamidele@outcess.com",
		"userId": "babatunde.01",
		"contactNo": "0802345678",
		"role": {
			"_id": "611a16e55c49bf01a82ab7bf",
			"roleName": "mis"
		},
		"reportsTo": {
			"_id": "611a175bf146e31cb4821082",
			"firstname": "Admin",
			"lastname": "User",
			"id": "611a175bf146e31cb4821082"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T14:17:48.095Z",
		"__v": 0,
		"lastLoginDate": "2021-12-16T11:36:09.060Z",
		"id": "6124ff8c0745811f48fba924"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "6124ff4f0745811f48fba905",
		"firstname": "Ayorinde",
		"middlename": "",
		"lastname": "Olamolu",
		"email": "ayorinde.olamolu@outcess.com",
		"userId": "ayorinde.01",
		"contactNo": "08912857467",
		"role": {
			"_id": "611a16e55c49bf01a82ab7bf",
			"roleName": "mis"
		},
		"reportsTo": {
			"_id": "611a175bf146e31cb4821082",
			"firstname": "Admin",
			"lastname": "User",
			"id": "611a175bf146e31cb4821082"
		},
		"createdBy": "611a175bf146e31cb4821082",
		"profilePic": "",
		"createdAt": "2021-08-24T14:16:47.845Z",
		"__v": 0,
		"lastLoginDate": "2024-02-21T14:58:36.502Z",
		"id": "6124ff4f0745811f48fba905"
	},
	{
		"isActive": true,
		"lob": "Branch",
		"loginFlag": true,
		"_id": "611a175bf146e31cb4821082",
		"firstname": "Admin",
		"middlename": "Admin",
		"lastname": "User",
		"email": "admin@outcess.com",
		"userId": "admin.01",
		"contactNo": "08165619894",
		"role": {
			"_id": "611a16e55c49bf01a82ab7c0",
			"roleName": "admin"
		},
		"reportsTo": {
			"_id": "611a175bf146e31cb4821082",
			"firstname": "Admin",
			"lastname": "User",
			"id": "611a175bf146e31cb4821082"
		},
		"createdAt": "2021-08-16T07:44:27.416Z",
		"__v": 0,
		"lastLoginDate": "2024-02-22T23:41:44.486Z",
		"aboutMe": "Outcess Admin",
		"profilePic": "image-1673438902383.jpeg",
		"id": "611a175bf146e31cb4821082"
	}
]